<template>
    <div v-loading.fullscreen.lock="loading">
        <car-business-type-search-bar-component
            add_auth="新增车辆业务类型"
            @search="get_car_business_type_index"
            @addTeam="addTeam"
        ></car-business-type-search-bar-component>
        <common-table-component
            details_auth="车辆业务类型详情"
            :col_arr="fcol_arr"
            :table_data="ftable_data"
            @details_row="details_car_business_type"
        ></common-table-component>
        <common-page-component
            :current_page="cond.page"
            :page_size="cond.limit"
            :total="ftotal"
            @search="get_car_business_type_index"
        ></common-page-component>
        <car-business-type-edit-component
            edit_auth="修改车辆业务类型"
            del_auth="删除车辆业务类型"
            :id="fid"
            :show_type="edit_type"
            :dialogFormVisible="fdialogFormVisible"
            :car_business_type_details_data="fcar_business_type_details_data"
            @exitDialog="dialogExit"
            @search="get_car_business_type_index"
            @show_edit="show_edit"
            @details_row="details_car_business_type"
        ></car-business-type-edit-component>
    </div>
</template>

<script>
import { car_business_type_index_request,car_business_type_details_request } from "@/network/WareHouse/CarBusinessType.js";

import CarBusinessTypeSearchBarComponent from "@/components/WareHouse/CarBusinessType/CarBusinessTypeSearchBarComponent";
import CommonTableComponent from "@/components/common/CommonTableComponent";
import CommonPageComponent from "@/components/common/CommonPageComponent";
import CarBusinessTypeEditComponent from "@/components/WareHouse/CarBusinessType/CarBusinessTypeEditComponent";

export default {
    name: 'CarBusinessTypeView',
    data() {
        return {
            fid: 0,
            edit_type: 0,
            fdialogFormVisible: false,
            loading: false,
            ftotal: 0,
            cond: {
                name: '',
                state: 1,
                page: 1,
                limit: 10,
            },
            ftable_data: [],
            fcol_arr: [
            {
                prop: 'name',
                label: '业务类型名称',
                minWidth: '200px',
            },
            {
                prop: 'state',
                label: '状态',
                minWidth: '100px',
                html: (row) => {
                    if(row['state']===1) {
                        return `<div class="cell"><span class="el-tag el-tag--success el-tag--plain">正常</span></div>`
                    } else {
                        return `<div class="cell"><span class="el-tag el-tag--danger el-tag--plain">已禁用</span></div>`
                    }
                }
            },
            {
                prop: 'create_staff_name',
                label: '创建人',
                minWidth: '80px',
            },
            {
                prop: 'create_time',
                label: '创建时间',
                minWidth: '80px',
            }
            ],
            fcar_business_type_details_data: {},
        };
    },
    computed: {},
    methods: {
        addTeam() {
            this.edit_type = 1;
            this.fdialogFormVisible = true;
        },
        show_edit() {
            this.edit_type = 2;
        },
        get_car_business_type_index(param = {}) {
            this.loading = true;
            this.cond.name = param.name ?? this.cond.name;
            this.cond.state = param.state ?? this.cond.state;
            this.cond.page = param.page ?? this.cond.page;
            if (param.limit) {
            this.cond.limit = param.limit;
            this.cond.page = 1;
            }
            car_business_type_index_request(this.cond)
            .then((s) => {
                this.loading = false;
                if (s.status === 0) {
                this.ftable_data = s.result.data;
                this.ftotal = s.result.total;
                } else {
                this.$message.error(s.msg);
                }
            })
            .catch((err) => {
                this.loading = false;
                this.$message.error(err);
            });
        },
        details_car_business_type(id) {
            this.fid = id;
            this.fdialogFormVisible = true;
            car_business_type_details_request(id)
            .then((s) => {
                if (s.status === 0) {
                this.fcar_business_type_details_data = s.result;
                } else {
                this.$message.error(s.msg);
                }
            })
            .catch((err) => {
                console.log(err);
            });
        },
        dialogExit() {
            this.fdialogFormVisible = false;
            this.fid = 0;
            this.edit_type = 0;
        },
    },
    filters: {},
    props: {},
    created() {
        this.get_car_business_type_index();
    },
    destoryed() {},
    mounted() {},
    updated() {},
    components: {
        CarBusinessTypeSearchBarComponent,
        CommonTableComponent,
        CommonPageComponent,
        CarBusinessTypeEditComponent,
    },
    watch: {},
};
</script>

<style lang="less"></style>
