<template>
    <div>
        <el-dialog
            :title="dialogFormTitle"
            :visible.sync="dialogFormVisible"
            :append-to-body="true"
            width="30%"
            :center="true"
            top="20vh"
            :close-on-click-modal="false"
            :before-close="() => closeDialog()"
            v-loading.fullscreen.lock="login_loading"
        >
            <div>
                <el-form
                    :model="CarBusinessType"    
                    :rules="rules"
                    ref="CarBusinessTypeEditForm"
                    label-width="120px"
                    size="small"
                >
                    <div v-if="show_type">
                        <el-form-item label="业务类型名称" prop="name">
                            <el-input v-model="CarBusinessType.name" clearable></el-input>
                        </el-form-item>
                        <el-form-item label="状态" prop="state" v-if="CarBusinessType.id">
                            <el-select
                                v-model="CarBusinessType.state"
                                placeholder="选择状态"
                                class="statec"
                                filterable
                                clearable
                            >
                                <el-option
                                    v-for="(v,i) in ['禁用','正常']"
                                    :key="v"
                                    :label="v"
                                    :value="i"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                    <div v-else>
                        <el-descriptions
                            class="margin-top"
                            :column="1"
                            :labelStyle="{'width':'120px','text-align':'center'}"
                            :contentStyle="{'text-align': 'center'}"
                            border
                        >
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>平台名称</span>
                                </template>
                                <span>{{CarBusinessType.name}}</span>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>费率</span>
                                </template>
                                <el-tag type="success" plain v-if="CarBusinessType.state === 1">正常</el-tag>
                                <el-tag type="danger" plain v-else>已禁用</el-tag>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>创建人</span>
                                </template>
                                <span>{{CarBusinessType.create_staff_name}}</span>
                            </el-descriptions-item>
                        </el-descriptions>
                    </div>
                </el-form>
            </div>
            <div slot="footer" class="dialog-footer">
                <div v-if="show_type===0">
                    <el-button
                        v-if="$_has(edit_auth)"
                        type="primary"
                        plain
                        @click="$emit('show_edit')"
                        >修改</el-button
                    >
                    <el-button
                        v-if="$_has(del_auth)"
                        type="danger"
                        plain
                        @click="CarBusinessTypeDel('CarBusinessTypeEditForm')"
                        >删除</el-button
                    >
                    <el-button
                        plain
                        @click="closeDialog()"
                        >关闭</el-button
                    >
                </div>
                <div v-else-if="show_type===1">
                    <el-button
                        type="success"
                        plain
                        @click="submitInsert('CarBusinessTypeEditForm')"
                        >确定</el-button
                    >
                    <el-button
                        type="warning"
                        plain
                        @click="resetForm('CarBusinessTypeEditForm')"
                        >重置</el-button
                    >
                    <el-button
                        plain
                        @click="closeDialog()"
                        >关闭</el-button
                    >
                </div>
                <div v-else>
                    <el-button
                        type="success"
                        plain
                        @click="submitEdit('CarBusinessTypeEditForm')"
                        >确定修改</el-button
                    >
                    <el-button plain @click="closeDialog()"
                        >取消操作</el-button
                    >
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { car_business_type_add_request,car_business_type_del_request,car_business_type_edit_request } from '@/network/WareHouse/CarBusinessType.js'

export default {
    name: 'CarBusinessTypeEditComponent',
    data() {
        return {
            login_loading: false,
            CarBusinessType: {
                name: '',
                state: '',
            },
            rules: {
                name: [
                    {
                        required: true,
                        message: '请输入平台名称',
                        trigger: 'blur',
                    },
                    {
                        min: 2,
                        max: 16,
                        message: '长度在 2 到 16 个字符',
                        trigger: 'blur',
                    },
                ],
            },
        }
    },
    computed: {
        dialogFormTitle() {
            if(this.show_type === 0) {
                return '车辆业务类型详情'
            } else if (this.show_type === 1) {
                return '新增车辆业务类型'
            } else {
                return '修改车辆业务类型'
            }
        },
    },
    methods: {
        closeDialog() {
            this.CarBusinessType = {
                name: '',
                state: ''
            }
            this.$emit('exitDialog')
        },
        submitInsert(formName) {
            this.$refs[formName].validate((valid) => {
                if (!valid) {
                    return
                }
                this.CarBusinessTypeInsert(formName)
            })
        },
        CarBusinessTypeInsert(formName) {
            this.login_loading = true
            car_business_type_add_request(this.CarBusinessType)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.$message.success('添加成功!')
                        this.$emit('exitDialog')
                        this.$emit('search')
                        this.$emit('details_row',s.result.id*1)
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        },
        resetForm(formName) {
            this.$refs[formName].resetFields()
        },
        submitEdit(formName) {
            this.$refs[formName].validate((valid) => {
                if (!valid) {
                    return
                }
                this.CarBusinessTypeEdit(formName)
            })
        },
        CarBusinessTypeEdit(formName) {
            this.login_loading = true
            this.CarBusinessType.id = this.id
            car_business_type_edit_request(this.CarBusinessType)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.$message.success('修改成功!')
                        this.$emit('exitDialog')
                        this.$emit('search')
                        this.$emit('details_row',this.CarBusinessType.id)
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        },
        CarBusinessTypeDel(formName) {
            this.$confirm(
                '是否确定删除？（一经删除，将无法再进行恢复，请谨慎操作！）',
                '温馨提示',
                {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }
            )
                .then(() => {
                    car_business_type_del_request(this.id)
                        .then((s) => {
                            if (s.status === 0) {
                                this.$message.success('删除成功!')
                                this.$emit('search')
                            } else {
                                this.$message.error(s.msg)
                            }
                        })
                        .catch((err) => {
                            this.$message.error(err)
                        })
                        .finally(() => this.closeDialog(formName))
                })
                .catch((err) => {})
        }
    },
    filters: {},
    props: {
        dialogFormVisible: Boolean,
        id: {
            type: Number,
            default() {
                return 0
            },
        },
        car_business_type_details_data: {
            type: Object,
            default() {
                return {}
            },
        },
        show_type: {
            type:Number,
            default() {
                return 0
            },
        },
        edit_auth: String,
        del_auth: String,
        state_auth: String
    },
    created() {},
    destoryed() {},
    mounted() {},
    updated() {},
    components: {},
    watch: {
        car_business_type_details_data(newVal) {
            this.CarBusinessType = newVal
        },
    },
}
</script>

<style lang='less'>
.statec {
    width: 100%;
}
</style>