import request from '../request'

//车辆业务类型列表
export const car_business_type_index_request = p => {
    return request({
        method:'GET',
        url:'car_business_type/index',
        params: p
    })
}

//添加车辆业务类型
export const car_business_type_add_request = d => {
    return request({
        method:'POST',
        url:'car_business_type/add',
        data: d
    })
}

//删除车辆业务类型
export const car_business_type_del_request = id => {
    return request({
        method:'DELETE',
        url:'car_business_type/del',
        data: {
            id: id
        }
    })
}

//修改车辆业务类型
export const car_business_type_edit_request = d => {
    return request({
        method:'PUT',
        url:'car_business_type/edit',
        data: d
    })
}

//获取车辆业务类型详情
export const car_business_type_details_request = id => {
    return request({
        method:'GET',
        url:'car_business_type/details',
        params: {
            id
        }
    })
}